import { memo } from 'react';
import TableHeader, { TableHeaderLabel } from './TableHead';
import classes from './styles.module.scss';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';

export enum ETYPE_ROW {
  DEFAULT,
  SUBTABLE,
}
export interface DataTables {
  type: ETYPE_ROW,
  subTitle?: string,
  note?: string,
  data?: (number | string)[],
}
interface Props {
  tableHeaders: TableHeaderLabel[];
  dataTables: DataTables[];
}

const TablePreview = memo(({tableHeaders, dataTables}: Props) => {
  return (
    <Table>
      <TableHeader headers={tableHeaders} />
      <TableBody className={classes.rawBody}>
        {dataTables.map((raw, key) => {
          if (raw.type === ETYPE_ROW.SUBTABLE) {
            if(raw?.note?.trim().length > 0){
              return(
                <TableRow hover tabIndex={-1} key={key}>
                  <TableCell sx={{ fontWeight: 'bold' }} component="th" colSpan={2}>
                    {raw.subTitle}
                  </TableCell>
                  <TableCell component="th" align='center' colSpan={(Number(tableHeaders.length) -2)}>
                    {raw.note}
                  </TableCell>
                </TableRow>
              )
            }else{
              return (
                <TableRow hover tabIndex={-1} key={key}>
                  <TableCell sx={{ fontWeight: 'bold' }} component="th" colSpan={tableHeaders.length}>
                    {raw.subTitle}
                  </TableCell>
                </TableRow>
              );
            }
            
          } else {
            return (
              <TableRow hover tabIndex={-1} key={key}>
                {raw.data.map((item, index) => {
                  return (
                    <TableCell component="th" key={index}>
                      {item}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          }
        })}
      </TableBody>
    </Table>
  );
});
export default TablePreview;
