import { memo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import classes from './styles.module.scss';

interface ConfirmModalProps {
  isOpen: boolean;
  title: string;
  description: string;
  titleBtnYes?: string;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmModal = memo((props: ConfirmModalProps) => {
  const { isOpen, title, description,titleBtnYes, onClose, onSubmit } = props;

  return (
    <Dialog transitionDuration={0.3} open={!!isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle className={classes.title}>{title ?? 'N/A'}</DialogTitle>
      <DialogContent>
        <p className={classes.description}>{description ?? 'N/A'}</p>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton onClick={onSubmit}>{titleBtnYes ?? `Submit`}</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmModal;
