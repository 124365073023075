import { memo } from 'react';
import Layout from '../Layout';
import Heading6 from '../Heading6';
import BodyTextSmall from '../BodyTextSmall';
import { Box } from '@mui/material';

// eslint-disable-next-line no-empty-pattern
// interface Props {}

const Page2 = memo(() => {
  return (
    <Layout>
      <Heading6 $fontWeight={600}>Introduction</Heading6>
      <Box pt={2} textAlign={'justify'}>
        <BodyTextSmall>The first section looks at what will not change in the short-term to 2026 - that is key demographic variables</BodyTextSmall>
        <BodyTextSmall>The impact of the economic shock on demographics is minor to negligible on the short term</BodyTextSmall>
        <BodyTextSmall>
          In the medium terms the changes may impact migration rates (lack of employment opportunities for migrants as a result of economic slowdown) and
          birth rates.
        </BodyTextSmall>
        <BodyTextSmall pt={2}>
          They do however, provide context for the subsequent section which looks at how a economic slowdown (or acceleration) impacts employment,
          productivity and total GDP, and then through changes in employment (changes in workers per household) and Productivity (impact on wages) impact
          on household incomes and expenditure
        </BodyTextSmall>
      </Box>
    </Layout>
  );
});
export default Page2;
