import classes from './styles.module.scss';
import { memo, useCallback, useState } from 'react';
import { Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Page1 from './component/Page1';
import Page2 from './component/Page2';
import Page3 from './component/Page3';

interface ModalReportProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalReport = memo(({ isOpen, onClose }: ModalReportProps) => {

  const [page, setPage] = useState<number>(1)

  const renderPage = useCallback(() => {
    switch(page){
      case 1: 
        return <Page1 />
      case 2:
        return <Page2/>
      case 3:
        return <Page3/>
      default:
        return <></>
    }
  },[page])

  const nextPage = () => {
    const _page: number = page + 1
    setPage(_page)
  }

  return (
    <Dialog
      PaperProps={{ style: { overflowY: 'visible' } }}
      maxWidth={'xl'}
      transitionDuration={0.3}
      open={isOpen}
      onClose={onClose}
      className={classes.container}
    >
      <DialogTitle className={classes.title} textAlign={'center'}>Preview report</DialogTitle>

      <DialogContent sx={{ overflowY: { md: 'visible' } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ borderRadius: '10px' }}>
              <CardContent className={classes.content} sx={{padding: "20px 0"}}>
                <div>{renderPage()}</div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ mt: 10 }} className={classes.dialogAction}>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2} sx={{ ml: 'auto' }}>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>

          <PrimaryButton form="form-download-table" onClick={nextPage}>Next page</PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});

export default ModalReport;
