import { Typography } from  '@mui/material';
import styled from 'styled-components';

interface Props {
    $colorName?: string;
    $fontWeight?: number | string;
}

const BodyText= styled(Typography)<Props>`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: ${props => props.$fontWeight || 400};
    font-size: 14px;
    line-height: 16px;
    color: ${props => `var(${props.$colorName || '--black'})`};
    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
`

export default BodyText;