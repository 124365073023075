import { memo } from 'react';
import classes from './styles.module.scss';

interface Props {
  children: React.ReactNode;
}
const Layout = memo((props: Props) => {
  const { children } = props;
  return (
    <div className={classes.root}>
      <div className={classes.main}>{children}</div>
    </div>
  );
});
export default Layout;
