import { Chip, Stack } from '@mui/material';
import { Fragment, memo, useState } from 'react';
import ReactSelect, { components, CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import classes from './styles.module.scss';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { CaretDownIcon } from 'assets';

const formStyles = (): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () =>
    ({
      display: 'none',
    } as CSSObjectWithLabel),
  control: (provided) =>
    ({
      ...provided,
      cursor: 'pointer',
      width: '100%',
      borderRadius: 8,
      border: '1px solid var(--gray)',
      boxShadow: 'none',
      background: 'transparent',
      '&:hover': {
        border: '1px solid var(--primary)',
      },
    } as CSSObjectWithLabel),
  valueContainer: (provided) =>
    ({
      ...provided,
      paddingLeft: 16,
      '> div': {
        maxWidth: '100%',
        overflow: 'hidden',
      },
    } as CSSObjectWithLabel),
  placeholder: (provided) =>
    ({
      ...provided,
      color: 'var(--gray)',
      fontSize: 16,
      fontWeight: 300,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    } as CSSObjectWithLabel),
  menuPortal: (provided) =>
    ({
      ...provided,
      zIndex: 999,
    } as CSSObjectWithLabel),
  menuList: (provided) =>
    ({
      ...provided,
      padding: 0,
      maxHeight: '500px',
    } as CSSObjectWithLabel),
  menu: (provided) =>
    ({
      ...provided,
      padding: '16px 8px',
      margin: 0,
    } as CSSObjectWithLabel),
  option: (provided, state) =>
    ({
      ...provided,
      display: 'inline-block',
      width: '50%',
      minWidth: '150px',
      cursor: 'pointer',
      color: state?.isSelected ? 'var(--primary)' : 'var(--black)',
      background: 'var(--backgroundDark)',
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 500,
      transition: 'all 0.2s',
      '@media screen and (max-width: 768px)': {
        width: '100%',
      },
      '&:hover': {
        background: 'var(--rowBackground)',
        transition: 'all 0.2s',
      },
      '&[aria-disabled="true"]': {
        color: 'var(--gray)',
        cursor: 'default',
        pointerEvents: 'none',
      },
    } as CSSObjectWithLabel),
  noOptionsMessage: (provided) =>
    ({
      ...provided,
      fontSize: 14,
    } as CSSObjectWithLabel),
  groupHeading: (provided) =>
    ({
      ...provided,
      color: 'var(--black)',
      fontWeight: 500,
      fontSize: 14,
    } as CSSObjectWithLabel),
  multiValue: (provided) => ({
    ...provided,
    maxWidth: '175px !important',
  }),
  group: (provided) => ({
    ...provided,
    padding: 0,
  }),
});

interface SelectProps extends StateManagerProps {
  className?: string;
  isMulti?: boolean;
  label?: string;
  errorMessage?: string;
  name?: string;
  control?: any;
  bindValue?: string;
  bindLabel?: string;
  collapsible?: boolean;
  hiddenOptionAll?: boolean;
  [key: string]: any;
}

const Select = memo((props: SelectProps) => {
  const { hiddenOptionAll, className, isMulti, label, errorMessage, name, control, bindValue, bindLabel, customTheme, collapsible, ...rest } = props;

  const [collapsedRegions, setCollapsedRegion] = useState<number[]>([]);

  const Group = (props: any) => {
    const selectedOptions = props?.getValue();
    const remainOptions = props?.options?.filter((option) => option?.isSelected === false && !option?.isDisabled);
    const handleSelectAll =
      remainOptions?.length > 0
        ? () => {
            props?.selectProps?.onChange([...selectedOptions, ...remainOptions]);
            setCollapsedRegion(collapsedRegions?.filter((collapsedRegion) => collapsedRegion !== props?.data?.id));
          }
        : null;
    const handleRemoveAll =
      remainOptions?.length < props?.options?.length
        ? () => props?.selectProps?.onChange(selectedOptions?.filter((option) => !props?.options?.map((option) => option?.value)?.includes(option?.value)))
        : null;
    return <components.Group {...props} headingProps={{ ...props.headingProps, handleSelectAll, handleRemoveAll }} />;
  };

  const GroupHeading = (props: any) => {
    const { handleSelectAll, handleRemoveAll, ...headingProps } = props;
    const handleCollapse = () => {
      if (collapsedRegions?.includes(props?.data?.id)) {
        setCollapsedRegion(collapsedRegions?.filter((collapsedRegion) => collapsedRegion !== props?.data?.id));
      } else {
        setCollapsedRegion([...collapsedRegions, props?.data?.id]);
      }
    };
    if(hiddenOptionAll) return null
    return (
      <components.GroupHeading
        {...headingProps}
        className={clsx(classes.groupHeading, {
          [classes.collapse]: collapsedRegions?.includes(props?.data?.id),
          [classes.collapsible]: collapsible,
        })}
        onClick={() => {
          if (collapsible) {
            handleCollapse();
          }
        }}
      >
        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} rowGap={1} columnGap={2} flexWrap={'wrap'}>
          <p className={classes.regionName}>
            {props?.children} {collapsible ? <CaretDownIcon /> : null}
          </p>
          <Stack direction="row" alignItems={'center'} rowGap={1} columnGap={2} flexWrap={'wrap'} sx={{ ml: 'auto' }}>
            <Chip
              className={classes.actionChip}
              disabled={!handleSelectAll}
              variant="filled"
              label="Select all"
              onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                event.stopPropagation();
                handleSelectAll?.();
              }}
            />
            <Chip
              className={classes.actionChip}
              disabled={!handleRemoveAll}
              variant="filled"
              label="Clear all"
              onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                event.stopPropagation();
                handleRemoveAll?.();
              }}
            />
          </Stack>
        </Stack>
      </components.GroupHeading>
    );
  };

  const MultiValue = (props: any) => {
    const { index, getValue, ...rest } = props;
    const maxToShow = 4;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);
    return index < maxToShow ? (
      <components.MultiValue {...rest} />
    ) : index === maxToShow ? (
      <p className={classes.moreSelected}>+{overflow.length} items selected</p>
    ) : null;
  };

  return (
    <Fragment>
      {control ? (
        <div className={clsx(classes.container, className)}>
          {label ? <label>{label}</label> : null}
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <ReactSelect
                {...field}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                className={className}
                isSearchable={true}
                styles={formStyles()}
                components={{ Group, GroupHeading, MultiValue }}
                menuPortalTarget={document.querySelector('body')}
                getOptionValue={(option: any) => option?.[bindValue || 'value']}
                getOptionLabel={(option: any) => option?.[bindLabel || 'label']}
                noOptionsMessage={() => 'No results found'}
                {...rest}
              />
            )}
          />
          {errorMessage ? <p>{errorMessage}</p> : null}
        </div>
      ) : (
        <div className={clsx(classes.container, className)}>
          {label ? <label>{label}</label> : null}
          <ReactSelect
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            blurInputOnSelect={false}
            backspaceRemovesValue={false}
            isMulti={isMulti}
            className={className}
            isSearchable={true}
            styles={formStyles()}
            components={{ Group, GroupHeading, MultiValue }}
            menuPortalTarget={document.querySelector('body')}
            getOptionValue={(option: any) => option?.[bindValue || 'value']}
            getOptionLabel={(option: any) => option?.[bindLabel || 'label']}
            noOptionsMessage={() => 'No results found'}
            {...rest}
          />
          {errorMessage ? <p>{errorMessage}</p> : null}
        </div>
      )}
    </Fragment>
  );
});

export default Select;
