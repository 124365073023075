import { Fragment, memo, useMemo } from 'react';
import classes from './styles.module.scss';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Regexes from 'configs/regexes';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import AuthService from 'services/authService';
import Input from 'components/Input';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ToastService from 'services/toastService';
import ApiService from 'services/apiService';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import apiRoutes from 'configs/apiRoutes';
import CryptoJS from 'crypto-js';
import { appRoutes } from 'routers/appRoutes';
import { DefaultLogo } from 'assets';
import { IUser } from 'interfaces/user';
import { EStatusCode } from 'configs/enums';
import { setUserReducer } from 'redux/reducers/User/actionTypes';
import { Grid, Typography } from '@mui/material';
interface ILoginFormData {
  email: string;
  password: string;
  // isAcceptTerms: boolean;
}

interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = memo((props: LoginPageProps) => {
  const dispatch = useDispatch();
  // const [openModalTerm, setOpenModalTerm] = useState<boolean>(false)

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().required('Please enter your email.').matches(Regexes.email, 'Please enter a valid email.'),
        password: Yup.string().required('Please enter your password.'),
        // isAcceptTerms: Yup.boolean().required("Accept Terms is required"),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const onSubmit = async (data: ILoginFormData) => {
    dispatch(setIsLoadingReducer(true));
    await ApiService.POST(apiRoutes.user.login, {
      email: data?.email,
      password: CryptoJS.AES.encrypt(data?.password, process.env.REACT_APP_AES_KEY).toString(),
    })
      .then((response) => {
        dispatch(setIsLoadingReducer(false));
        const user: IUser = response?.data?.user;
        if (user?.isVerified) {
          AuthService.login(response?.data);
          dispatch(setUserReducer(user));
          dispatch(push(appRoutes.private.home));
        } else {
          dispatch(
            push({
              pathname: appRoutes.fullyAllowed.checkEmail,
              search: `&email=${encodeURIComponent(data?.email)}`,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(setIsLoadingReducer(false));
        switch (error?.response?.status) {
          case EStatusCode.NotFound:
            return ToastService.error(Messages.error.wrongEmail);
          case EStatusCode.BadRequest:
            return ToastService.error(Messages.error.wrongPassword);
          case EStatusCode.AccessDenied:
            return ToastService.error(Messages.error.suspendedAccount);
          default:
            return ToastService.error(Messages.error.default);
        }
      });
  };

  return (
    <Fragment>
      <Link to={appRoutes.private.home}>
        <img className={classes.logoImage} src={DefaultLogo} alt="Logo" />
      </Link>

      <p className={classes.title}>Login</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Email"
          inputRef={register('email')}
          type="text"
          placeholder="Your email"
          autoComplete="email"
          errorMessage={errors?.email?.message}
        />

        <Input
          label="Password"
          inputRef={register('password')}
          type="password"
          placeholder="Your password"
          autoComplete="password"
          errorMessage={errors?.password?.message}
        />

        <Link className={classes.forgotPassword} to={appRoutes.fullyAllowed.forgotPassword}>
          Forgot your password?
        </Link>

        <PrimaryButton className="mb-4" type="submit" fullWidth>
          Login
        </PrimaryButton>

        <div className={classes.registerContainer}>
          <p>Don't have an account?</p>
          <Link to={appRoutes.public.register}>Create account</Link>
        </div>
        <Grid sx={{textAlign: "justify", display: "flex", flexDirection: "column", gap: "8px"}}>
          <Typography sx={{fontSize: 16, fontWeight: 600}}>By logging in you accept these terms and conditions</Typography>
          <p>This Model was produced by Global Demographics Ltd as of the date of delivery and is subject to change. Global Demographics Ltd reserves the right to change the information in this model at any time without further notice.   The information contained herein is given in good faith but without guarantee and Global Demographics Ltd do not make any representation as to its accuracy or completeness and do not accept liability for any loss and/or damage arising from the use herein.  Neither this model nor its contents may be used, copied, reproduced, disseminated, quoted or referred to in any presentation, agreement or document (with or without attribution to Global Demographics Ltd) at any time or in any manner without the express permission by Global Demographics Ltd.</p>
        </Grid>
        {/* <FormControlLabel
          control={
            <Controller
              name="isAcceptTerms"
              control={control}
              render={({ field }) => <Checkbox checked={field.value} {...field}/>}
            />
          }
          label={<span className={classes.titleTerm}>I Accept <span onClick={() => {setOpenModalTerm(true)}} className={classes.textTerms}> Terms</span></span>}
        />
        {errors.isAcceptTerms && (
          <p className={classes.textError}>{errors.isAcceptTerms.message}</p>
        )} */}
      </form>
      {/* <TermModal
        isOpen={openModalTerm}
        onClose={setOpenModalTerm}
      /> */}
    </Fragment>
  );
});

export default LoginPage;
