import { IData } from 'interfaces/demographics';
import { memo, useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface DataTableChartProps {
  title: string;
  unit: string;
  isPercentage: boolean;
  dataSeries: [IData, IData];
  yearRange: [number, number];
  forecastYear: number;
}

const DataTableChart: React.FC<DataTableChartProps> = memo((props: DataTableChartProps) => {
  const { title, unit, isPercentage, dataSeries, yearRange, forecastYear } = props;

  const baseCaseSeries = useMemo(
    () =>
      dataSeries?.[0]
        ? Object.keys(dataSeries?.[0])
            ?.filter((year) => parseInt(year) >= yearRange?.[0] && parseInt(year) <= yearRange?.[1])
            ?.map((year) => [parseInt(year), dataSeries?.[0]?.[year]])
        : [],
    [dataSeries, yearRange]
  );

  const revisedSeries = useMemo(
    () =>
      dataSeries?.[1]
        ? Object.keys(dataSeries?.[1])
            ?.filter((year) => parseInt(year) >= yearRange?.[0] && parseInt(year) <= yearRange?.[1])
            ?.map((year) => [parseInt(year), dataSeries?.[1]?.[year]])
        : [],
    [dataSeries, yearRange]
  );

  useEffect(() => {
    console.log(JSON.stringify(baseCaseSeries),'99999')
  },[baseCaseSeries])

  const options: Highcharts.Options = {
    title: {
      text: title,
    },
    xAxis: {
      title: {
        text: 'Year',
      },
      plotLines: [
        {
          color: 'gray',
          dashStyle: 'ShortDash',
          value: (forecastYear ?? 2023) - 1,
          width: 2,
        },
      ],
    },
    yAxis: {
      title: {
        text: unit ?? 'N/A',
      },
      ...(isPercentage
        ? {
            labels: {
              formatter: function () {
                return (parseFloat(`${this.value}`) * 100).toFixed(2) + '%';
              },
            },
          }
        : {}),
    },
    ...(isPercentage
      ? {
          tooltip: {
            formatter: function () {
              return '<b>' + this.series.name + '</b>: ' + Highcharts.numberFormat(this.y * 100, 2) + ' %';
            },
          },
        }
      : {}),
    series: [
      {
        type: 'line',
        name: 'Base Case',
        data: baseCaseSeries,
      },
      {
        type: 'line',
        name: 'Revised',
        data: revisedSeries,
      },
    ],
    accessibility: {
      enabled: false,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
});

export default DataTableChart;
