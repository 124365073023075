import { memo, useEffect, useState } from "react";
import classes from './styles.module.scss';
import clsx from 'clsx';
import { Grid, MenuItem, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IDemographicsData } from "interfaces/demographics";
import { useSelector } from "react-redux";
import { IReducer } from "redux/reducers";
import { DataFilter } from "..";

interface ModalGrowthRateProps {
  demographicsData?: IDemographicsData;
  dataFilter?: DataFilter;
  isOpen: boolean,
  hiddenSubTableIds?: number[];
}
interface DataSubtable {
  [key: string | number]: number;
}
interface Subtable {
  id: number,
  name: string,
  variables: Variable[],
}
interface Variable {
  id: number,
  variableId: number,
  name: string,
  topicId?: number,
  data?: DataSubtable,
  isPercentage?: boolean
 
}

const ModalGrowthRate = memo(({ demographicsData, dataFilter, isOpen, hiddenSubTableIds }: ModalGrowthRateProps) => {

  const { dataControl } = useSelector((state: IReducer) => state.dataControl);

  const [subTableSelected, setSubTableSelected] = useState<number>(1);
  const [variableSelected, setVariableSelected] = useState<number>(1);
  const [startYearSelected, setStartYearSelected] = useState<number>(0);
  const [endYearSelected, setEndYearSelected] = useState<number>(0);
  const [optionVariable, setOptionVariable] = useState<Variable[]>([]);
  const [dataSubtable, setDataSubtable] = useState<Subtable[]>([]);
  const [resultCAGR, setResultCAGR] = useState<string>("");
  const [resultAverage, setResultAverage] = useState<string>("");

  const [errors, setErrors] = useState({
    subTable: false,
    variable: false,
    startYear: false,
    endYear: false,
  });

  const handleChangeSubtable = (event: SelectChangeEvent) => {
    const subtableId = +event.target.value;
    setSubTableSelected(subtableId);
    setVariableSelected(null)
    setResultCAGR(null)
    setResultAverage(null)
    const _optionVariable = dataSubtable?.find(it => it.id === subtableId)?.variables ?? [];
    setOptionVariable(_optionVariable)
    setErrors({
      subTable: false,
      variable: false,
      startYear: false,
      endYear: false,
    })
  };
  const handleChangeVariable = (event: SelectChangeEvent) => {
    setVariableSelected(+event.target.value);
    setResultCAGR(null)
    setResultAverage(null)
    setErrors({
      subTable: false,
      variable: false,
      startYear: false,
      endYear: false,
    })
  };

  const handleChangeStartYear = (event: SelectChangeEvent) => {
    setStartYearSelected(+event.target.value);
    setResultCAGR(null)
    setResultAverage(null)
    setErrors({
      subTable: false,
      variable: false,
      startYear: false,
      endYear: false,
    })
  };

  const handleChangeEndYear = (event: SelectChangeEvent) => {
    setEndYearSelected(+event.target.value);
    setResultCAGR(null)
    setResultAverage(null)
    setErrors({
      subTable: false,
      variable: false,
      startYear: false,
      endYear: false,
    })
  };

  const resetForm = () => {
    setSubTableSelected(null)
    setVariableSelected(null)
    setStartYearSelected(0)
    setEndYearSelected(0)
    setResultCAGR(null)
    setResultAverage(null)
    setErrors({
      subTable: false,
      variable: false,
      startYear: false,
      endYear: false,
    })
  }

  const validateForm = () => {
    const newErrors = {
      subTable: !subTableSelected,
      variable: !variableSelected,
      startYear: !startYearSelected,
      endYear: !endYearSelected || endYearSelected <= startYearSelected,
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const setDataVariable = () => {
    const _dataSubtable: Subtable[] = [];
    let i = 1;
    demographicsData?.topic?.forEach(topic => {
      topic?.subTables?.forEach(subtable => {
        const index = _dataSubtable?.findIndex(it => it.id === subtable?.id)
        if (index === -1) {
          const _itemSubtable: Subtable = {
            id: subtable.id,
            name: subtable.name,
            variables: []
          }
          subtable?.dataRow?.forEach(row => {
            _itemSubtable.variables.push({
              id: i,
              variableId: row.id,
              name: row.name,
              topicId: topic.id,
              data: row.data,
              isPercentage: row?.isPercentage
            })
            i++;
          })
          _dataSubtable.push(_itemSubtable)
        } else {
          subtable?.dataRow?.forEach(row => {
            _dataSubtable[index].variables.push({
              id: i,
              variableId: row.id,
              name: row.name,
              topicId: topic.id,
              data: row.data,
              isPercentage: row?.isPercentage
            })
            i++;
          })
        }
      })

    });
    return _dataSubtable
  }

  const onSubmitCalculate = () => {

    if (!validateForm()) {
      return;
    }
    setErrors({
      subTable: false,
      variable: false,
      startYear: false,
      endYear: false,
    })
    let startValue = 0
    let endValue = 0
    let isPercentage = false

    dataSubtable?.forEach(sub => {
      if (sub?.id === subTableSelected) {
        sub?.variables?.forEach(variables => {
          if (variables?.id === variableSelected) {
            startValue = variables?.data[startYearSelected] ?? 0
            endValue = variables?.data[endYearSelected] ?? 0
            isPercentage = variables?.isPercentage
          }
        })
      }
    })

    const n = endYearSelected - startYearSelected;
    const CAGR = startValue && endValue
      ? ((endValue / startValue) ** (1 / n)) - 1
      : 0;

    const average = (startValue + endValue) / 2;

    const stringAverage = isPercentage ? `${(average * 100).toFixed(2)}%` :`${(average).toFixed(2)}`

    setResultCAGR(`${(CAGR * 100).toFixed(2)}%`)
    setResultAverage(stringAverage)


  }

  useEffect(() => {
    if (dataFilter && demographicsData) {
      resetForm();
      Promise.all([
        setDataVariable(),
      ]).then(([_dataSubtable]) => {
        const _optionVariable = _dataSubtable?.find(it => it.id === dataFilter?.subTable[0])?.variables ?? [];
        setOptionVariable(_optionVariable)
        setDataSubtable(_dataSubtable);
        setStartYearSelected(dataFilter?.yearRange[0] ?? 0);
        setEndYearSelected(dataFilter?.yearRange[0] ?? 0);
      })
    }

  }, [dataFilter, demographicsData]);


  return (
    <div className={clsx(classes.contentCalculate, { [classes.show]: !!isOpen })}>
      <Grid container columnSpacing={6}>
        <Grid item xs={12} sm={12}>
          <label style={{ fontWeight: '600' }}>Select sub table:</label>
          <Select
            className={clsx(classes.variableSelect, classes.inputCalculate)}
            name="subTable"
            value={subTableSelected?.toString() ?? ""}
            onChange={handleChangeSubtable}
          >
            {
              dataControl?.subTables?.map((it) => {
                return (dataFilter?.subTable?.includes(it.id) && !hiddenSubTableIds?.includes(it.id)) && <MenuItem value={it.id} key={it.id}>{it.name}</MenuItem>
              })
            }
          </Select>
          {errors.subTable && <Typography color="error" mb={1.5}>Please select a sub table.</Typography>}
        </Grid>
        <Grid item xs={12} sm={12}>
          <label style={{ fontWeight: '600' }}>Select Variable:</label>
          <Select
            className={clsx(classes.variableSelect, classes.inputCalculate)}
            name="variable"
            value={variableSelected?.toString() ?? ""}
            onChange={handleChangeVariable}
          >
            {optionVariable?.map((it) => (
              <MenuItem value={it.id} key={it.id}>{it.name}</MenuItem>
            ))}
          </Select>
          {errors.variable && <Typography color="error" mb={1.5}>Please select a variable.</Typography>}
        </Grid>
        <Grid item xs={12} sm={12}>
          <label style={{ fontWeight: '600' }}>Start year:</label>
          <Select
            className={clsx(classes.variableSelect, classes.inputCalculate)}
            name="startYear"
            value={startYearSelected?.toString() ?? ""}
            onChange={handleChangeStartYear}
          >
            {demographicsData?.yearRange?.map((year, index) => (
              <MenuItem value={year} key={index}>{year}</MenuItem>
            ))}
          </Select>
          {errors.startYear && <Typography color="error" mb={1.5}>Please select a start year.</Typography>}
        </Grid>
        <Grid item xs={12} sm={12}>
          <label style={{ fontWeight: '600' }}>End year:</label>
          <Select
            className={clsx(classes.variableSelect, classes.inputCalculate)}
            name="endYear"
            value={endYearSelected?.toString() ?? ""}
            onChange={handleChangeEndYear}
          >
            {demographicsData?.yearRange?.map((year, index) => (
              <MenuItem value={year} key={index}>{year}</MenuItem>
            ))}
          </Select>
          {errors.endYear && (
            <Typography color="error" mb={1.5}>
              {endYearSelected <= startYearSelected
                ? "End year must be greater than Start year."
                : "Please select an end year."}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <button className={clsx(classes.btnCAGR, classes.fullWidth)} type="button" onClick={onSubmitCalculate}>
            Calculate
          </button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography className={classes.resultCAGR}>CAGR: {`${resultCAGR ? resultCAGR : '--'}`}</Typography>
          <Typography className={classes.resultCAGR}>AVERAGE: {`${resultAverage ? resultAverage : '--'}`}</Typography>
        </Grid>
      </Grid>
    </div>
  )
})
export default ModalGrowthRate;