import { memo } from 'react';
import Layout from '../Layout';
import Heading4 from '../Heading4';
import BodyText from '../BodyText';
import { Box } from '@mui/material';
import BodyTextSmall from '../BodyTextSmall';

// eslint-disable-next-line no-empty-pattern
// interface Page1Props {}

const Page1 = memo(() => {
  return (
    <Layout>
      <Heading4 $fontWeight={500}>Demographic Forecast from 2010 to 2022 Actual and as Forecast to 2045</Heading4>
      <Heading4 $fontWeight={800} $colorName='--red' textAlign={'center'} pt={2}>United States of America</Heading4>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box width={"50%"} pt={2}>
          <Box sx={{display: 'flex', justifyContent:"space-between"}}>
            <BodyText>Prepared by:</BodyText>
            <BodyText>Global Demographics Ltd</BodyText>
          </Box>
          <Box sx={{display: 'flex', justifyContent:"space-between"}}>
            <BodyText>Published:</BodyText>
            <BodyText></BodyText>
          </Box>
          <Box sx={{display: 'flex', justifyContent:"space-between"}}>
            <BodyText>Contact:</BodyText>
            <a href="mailto:admin@globaldemographics.com">admin@globaldemographics.com</a>
          </Box>
          <Box sx={{display: 'flex', justifyContent:"space-between"}}>
            <BodyText></BodyText>
            <BodyText>Ph - HK: +852 8121 2907</BodyText>
          </Box>
          <Box sx={{display: 'flex', justifyContent:"space-between"}}>
            <BodyText></BodyText>
            <BodyText>Ph - UK: +44 20 3286 3492</BodyText>
          </Box>
          <Box sx={{display: 'flex', justifyContent:"space-between"}}>
            <BodyText></BodyText>
            <BodyText>Tai Yau Building</BodyText>
          </Box>
          <Box sx={{display: 'flex', justifyContent:"space-between"}}>
            <BodyText></BodyText>
            <BodyText>181 Johnston Road</BodyText>
          </Box>
          <Box sx={{display: 'flex', justifyContent:"space-between"}}>
            <BodyText></BodyText>
            <BodyText>Wanchai</BodyText>
          </Box>
          <Box sx={{display: 'flex', justifyContent:"space-between"}}>
            <BodyText></BodyText>
            <BodyText>Hong Kong</BodyText>
          </Box>
          <Box sx={{display: 'flex', justifyContent:"space-between"}}>
            <BodyText>Copyright</BodyText>
            <BodyText>Global Demographics Ltd</BodyText>
          </Box>
        </Box>
      </Box>
      <Box width={"100%"} textAlign={"justify"} pt={2}>
        <BodyTextSmall>
          Global Demographics Ltd makes no warranties, expressed or implied, as to results to be obtained by any person or entity from the use of the information provided and makes no warranties of fitness for a particular purpose or use.  In no event shall Global Demographics Ltd or its Affiliates be liable to any user or third parties for direct, indirect, incidental, special or consequential damages, however arising, including without limitation in the event such damages were due to the negligence of Global Demographics Ltd or whether such damages were foreseen or unforseen.
        </BodyTextSmall>
      </Box>
    </Layout>
  );
});

export default Page1;
