import { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface Props {
  title: string;
  isPercentage: boolean;
}

const dataTablesBaseCase: any[] = [
  [2010, 311.183],
  [2015, 324.608],
  [2020, 335.942],
  [2025, 338.103],
  [2030, 351.405],
  [2035, 357.183],
  [2040, 361.528],
  [2045, 364.512],
];
const dataTablesScenario: any[] = [
  [2010, 311.183],
  [2015, 324.608],
  [2020, 335.942],
  [2025, 338.103],
  [2030, 351.405],
  [2035, 357.183],
  [2040, 361.528],
  [2045, 364.512],
];

const ChartArea = memo(({ title, isPercentage }: Props) => {
  const options: Highcharts.Options = {
    title: {
      text: title,
    },
    xAxis: {
      title: {
        text: 'Year',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      ...(isPercentage
        ? {
            labels: {
              formatter: function () {
                return (parseFloat(`${this.value}`) * 100).toFixed(2) + '%';
              },
            },
          }
        : {}),
    },
    ...(isPercentage
      ? {
          tooltip: {
            formatter: function () {
              return '<b>' + this.series.name + '</b>: ' + Highcharts.numberFormat(this.y * 100, 2) + ' %';
            },
          },
        }
      : {}),
    series: [
      {
        type: 'line',
        name: 'Base Case',
        data: dataTablesBaseCase,
      },
      {
        type: 'line',
        name: 'Revised',
        data: dataTablesScenario,
      },
    ],
    accessibility: {
      enabled: false,
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
});
export default ChartArea;
