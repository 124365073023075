import { yupResolver } from '@hookform/resolvers/yup';
import { IChangeDemographicsParams, IDemographicsView, IGetDemographicsFormData, IGetDemographicsParams } from 'interfaces/demographics';
import { memo, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import classes from './styles.module.scss';
import dayjs from 'dayjs';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Switch } from '@mui/material';
import CustomDatePicker from 'components/DatePicker';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { CaretDownIcon, CloseIcon, PlusBlackIcon } from 'assets';
import Select from 'components/Select';
import Input from 'components/Input';
import CommonService from 'services/commonService';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import { getDataControlRequest } from 'redux/reducers/DataControl/actionTypes';
import { ETopic, EUserType } from 'configs/enums';
import { TRIAL_COUNTRY } from 'configs/constant';
import QueryString from 'qs';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import { appRoutes } from 'routers/appRoutes';
import { orderBy } from 'lodash';
import ModalUploadScenario from '../ModalUploadScenario';
import { IConfirmModal } from 'interfaces/common';
import ConfirmModal from 'components/Modals/ConfirmModal';
import clsx from 'clsx';
interface DataControlProps {
  onSettingReport: () => void;
  onSubmit: (data: IGetDemographicsFormData) => void;
  onViewChange: (newView: IDemographicsView) => void;
  handleSetIsCountryChange: (data: boolean) => void;
  onChangeWidthDataUpload: (data: IChangeDemographicsParams) => void;
  setIsSubmitFormSaveChange: (data: boolean) => void;
  isSubmitForm: number;
  isHaveDatatabe?: boolean;
}

const DataControl: React.FC<DataControlProps> = memo((props: DataControlProps) => {
  const { onViewChange, onSubmit,handleSetIsCountryChange,setIsSubmitFormSaveChange,onSettingReport, isSubmitForm, onChangeWidthDataUpload,isHaveDatatabe } = props;

  const dispatch = useDispatch();

  const { user } = useSelector((state: IReducer) => state?.user);
  const { dataControl } = useSelector((state: IReducer) => state.dataControl);

  const [showCustomBreakpoints, setShowCustomBreakpoints] = useState<boolean>(false);
  const [isBoundQuery, setIsBoundQuery] = useState<boolean>(false);

  const query = useMemo(() => QueryString.parse(window?.location?.search, { comma: true, ignoreQueryPrefix: true }), [window?.location?.search]);

  const [isContryChange, setIsContryChange] = useState<boolean>(false);
  const [isDataControlChange, setIsDataControlChange] = useState<boolean>(false);

  const [showModalUpload, setShowModalUpload] = useState<boolean>(false);
  
  const [dataUpload, setDataUpload] = useState<IChangeDemographicsParams>(null);

  const [confirmModal, setConfirmModal] = useState<IConfirmModal>(null);

  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      {
        country: Yup.array().of(Yup.object()).min(1, 'Select at least one country.').required('Select at least one country.'),
        topic: Yup.array().of(Yup.object()).min(1, 'Select at least one topic to show.').required('Select at least one topic to show.'),
        subTable: Yup.array().of(Yup.object()).min(1, 'Select at least one sub table to show.').required('Select at least one sub table to show.'),
        startYear: Yup.date()
          .required('Start year is required.')
          .typeError('Start year is required.')
          .when('endYear', (endYear, schema) => {
            if (endYear && dayjs(endYear).isValid()) {
              return schema.max(endYear, 'Start year must be before end year.').typeError('Start year is required.');
            }
          }),
        endYear: Yup.date()
          .required('End year is required.')
          .typeError('End year is required.')
          .when('startYear', (startYear, schema) => {
            if (startYear && dayjs(startYear).isValid()) {
              return schema.min(startYear, 'End year must be after start year.').typeError('End year is required.');
            }
          }),
        stepSize: Yup.number().required('Step size is required.').typeError('Step size is required.').min(1, 'Step size must be greater than 1.'),
        baseYear: Yup.date()
          .required('Base year is required.')
          .typeError('Base year is required.')
          .min(Yup.ref('startYear'), 'Base year must be between start year and end year.')
          .max(Yup.ref('endYear'), 'Base year must be between start year and end year.'),
        customBreakpoints: Yup.bool(),
        breakpoints: Yup.array().when('customBreakpoints', {
          is: true,
          then: Yup.array(
            Yup.object().shape({
              value: Yup.number()
                .required('Breakpoint is required.')
                .typeError('Breakpoint is required.')
                .moreThan(0, 'Income must be more than 0')
                .max(9999999999, 'Income too big'),
            })
          )
            .test('is-increasing', 'Each breakpoint value must be greater than the previous one', function (breakpoints) {
              if (breakpoints) {
                for (let i = 1; i < breakpoints.length; i++) {
                  if (breakpoints[i].value <= breakpoints[i - 1].value) {
                    return (this as Yup.TestContext)?.createError({
                      path: `breakpoints.${i}.value`,
                      message: `Each breakpoint value must be greater than the previous one.`,
                    });
                  }
                }
              }
              return true;
            })
            .required('Breakpoint is required.')
            .min(1, 'Breakpoint is required.'),
        }),
      },
      [['startYear', 'endYear']]
    );
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isDirty },
    trigger,
    control,
  } = useForm<IGetDemographicsFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      stepSize: 5,
      customBreakpoints: false,
      startYear: dayjs("2005-01-01"),
      endYear: dayjs("2065-01-01"),
      baseYear: dayjs("2024-01-01"),
      breakpoints: [
        {
          value: undefined,
        },
      ],
    },
  });
  
  const {
    fields: fieldsBreakpoints,
    append: appendBreakpoint,
    remove: removeBreakpoint,
  } = useFieldArray({
    control,
    name: 'breakpoints',
  });

  const watchStartYear = watch('startYear');
  const watchEndYear = watch('endYear');
  const watchBaseYear = watch('baseYear');
  const watchSubTable = watch('subTable');
  const watchCountry = watch('country');
  const watchTopic = watch('topic');
  const watchStepSize = watch('stepSize');

  useEffect(() => {
    if (!dataControl) {
      dispatch(getDataControlRequest());
    }
  }, [dataControl]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if(["startYear","endYear","baseYear"].includes(name)) setIsSubmitFormSaveChange(true)
      if (name !== 'country') {
        onViewChange({
          topic: value?.topic?.map((option) => parseInt(`${option?.value}`)) ?? [],
          subTable: value?.subTable?.map((option) => parseInt(`${option?.value}`)) ?? [],
          startYear: (value?.startYear as dayjs.Dayjs)?.year(),
          endYear: (value?.endYear as dayjs.Dayjs)?.year(),
          stepSize: parseInt(`${value?.stepSize}`),
          baseYear: (value?.baseYear as dayjs.Dayjs)?.year(),
          breakpoints: value?.breakpoints ? value?.breakpoints : [],
          customBreakpoints: value?.customBreakpoints ?? false
        });
      }else{
        handleSetIsCountryChange(true)
      }
      const params: IGetDemographicsParams = {
        ...(value?.country?.length > 0 ? { country: value?.country?.map((country) => country.value) } : {}),
        ...(value?.topic?.length > 0 ? { topic: value?.topic?.map((topic) => topic.value) } : {}),
        ...(value?.subTable?.length > 0 ? { subTable: value?.subTable?.map((subTable) => subTable.value) } : {}),
        ...(value?.startYear ? { startYear: (value?.startYear as dayjs.Dayjs)?.year() } : {}),
        ...(value?.endYear ? { endYear: (value?.endYear as dayjs.Dayjs)?.year() } : {}),
        ...(value?.stepSize ? { stepSize: value?.stepSize } : {}),
        ...(value?.baseYear ? { baseYear: (value?.baseYear as dayjs.Dayjs)?.year() } : {}),
        ...(value?.breakpoints?.length > 0 && value?.customBreakpoints
          ? { breakpoint: value?.breakpoints?.map((breakpoint) => breakpoint.value) ?? [] }
          : {}),
      };
      if (Object.keys(params)?.length > 0) {
        const newUrl = `${window.location.pathname}?${QueryString.stringify(params, { arrayFormat: 'comma' })}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
      } else {
        window.history.pushState({ path: window.location.pathname }, '', window.location.pathname);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (watchTopic?.some((option) => option.value === ETopic.DistributionHouseholds || option.value === ETopic.HouseholdExp)) {
      setShowCustomBreakpoints(true);
    } else {
      setShowCustomBreakpoints(false);
    }
  }, [watchCountry, watchTopic]);

  useEffect(() => {
    if (isBoundQuery && isValid && !isDirty) {
      handleSubmit(onSubmit)();
      setIsBoundQuery(null);
    }
  }, [isBoundQuery, isValid, isDirty]);

  useEffect(() => {
    if (dataControl && isBoundQuery === false) {
      if (Object.keys(query ?? {}).length > 0) {
        const selectedCountry = query?.country ? query?.country?.toString()?.split(',')?.map(Number) : [];
        const selectedTopic = query?.topic ? query?.topic?.toString()?.split(',')?.map(Number) : [];
        const selectedSubTable = query?.subTable ? query?.subTable?.toString()?.split(',')?.map(Number) : [];
        const startYear = parseInt(`${query?.startYear}`);
        const endYear = parseInt(`${query?.endYear}`);
        const baseYear = parseInt(`${query?.baseYear}`);
        const stepSize = parseInt(`${query?.stepSize}`);
        const breakpoints = query?.breakpoint ? query?.breakpoint?.toString()?.split(',')?.map(Number) : [];
        reset({
          country: dataControl?.regions
            ?.flatMap((region) => region.countries)
            ?.filter((country) => selectedCountry.includes(country.id))
            ?.filter((country) =>
              user?.type === EUserType.Admin || user?.type === EUserType.Demo || user?.subscription ? true : country.name === TRIAL_COUNTRY
            )
            ?.map((country) => ({ value: country.id, label: country.name })),
          topic: dataControl?.topics?.filter((topic) => selectedTopic.includes(topic.id))?.map((topic) => ({ value: topic.id, label: topic.name })),
          subTable: dataControl?.subTables
            ?.filter((subTable) => selectedSubTable.includes(subTable.id))
            ?.map((subTable) => ({ value: subTable.id, label: subTable.name })),
          ...(startYear && { startYear: dayjs(`${startYear}`) }),
          ...(endYear && { endYear: dayjs(`${endYear}`) }),
          ...(baseYear && { baseYear: dayjs(`${baseYear}`) }),
          ...(stepSize && { stepSize: stepSize }),
          ...(breakpoints &&
            breakpoints?.length > 0 && {
              customBreakpoints: true,
              breakpoints: breakpoints?.map((breakpoint) => ({ value: breakpoint })),
            }),
        });
        setIsBoundQuery(true);
      } else {
        setIsBoundQuery(null);
      }
    }
  }, [dataControl, query, isBoundQuery]);
  useEffect(() => {
    if(dataUpload?.country?.length){
      
      const selectedCountry = dataUpload?.country || [];
      const selectedTopic = dataUpload?.topic || [];
      const selectedSubTable = dataUpload?.subTable || [];
      const startYear = parseInt(`${dataUpload?.startYear}`);
      const endYear = parseInt(`${dataUpload?.endYear}`);
      const baseYear = parseInt(`${dataUpload?.baseYear}`);
      const stepSize = parseInt(`${dataUpload?.stepSize}`);
      const breakpoints = dataUpload?.breakpoint || [];
      reset({
        country: dataControl?.regions
          ?.flatMap((region) => region.countries)
          ?.filter((country) => selectedCountry.includes(country?.id))
          ?.filter((country) =>
            user?.type === EUserType.Admin || user?.type === EUserType.Demo || user?.subscription ? true : country.name === TRIAL_COUNTRY
          )
          ?.map((country) => ({ value: country.id, label: country.name })),
        topic: dataControl?.topics?.filter((topic) => selectedTopic.includes(topic.id))?.map((topic) => ({ value: topic.id, label: topic.name })),
        subTable: dataControl?.subTables
          ?.filter((subTable) => selectedSubTable.includes(subTable.id))
          ?.map((subTable) => ({ value: subTable.id, label: subTable.name })),
        ...(startYear && { startYear: dayjs(`${startYear}`) }),
        ...(endYear && { endYear: dayjs(`${endYear}`) }),
        ...(baseYear && { baseYear: dayjs(`${baseYear}`) }),
        ...(stepSize && { stepSize: stepSize }),
        ...(breakpoints &&
          breakpoints?.length > 0 && {
            customBreakpoints: true,
            breakpoints: breakpoints?.map((breakpoint) => ({ value: breakpoint })),
          }),
      });
      onChangeWidthDataUpload(dataUpload)
    }
  },[dataUpload])
  useEffect(() => {
    if (isSubmitForm && watchCountry?.length) {
      setIsContryChange(true)
    }
  }, [watchCountry]);

  useEffect(() => {
    if (isSubmitForm)
      setIsDataControlChange(true)
  }, [watchStartYear, watchEndYear, watchBaseYear, watchTopic,watchSubTable,watchStepSize]);

  useEffect(() => {
    setIsContryChange(false)
    setIsDataControlChange(false)
  },[isSubmitForm])

  useEffect(() => {
    if (watchStartYear && watchEndYear && watchBaseYear) {
      trigger(['startYear', 'endYear', 'baseYear']);
    } else {
      if (watchStartYear && watchEndYear) {
        trigger(['startYear', 'endYear']);
      }
    }
  }, [watchStartYear, watchEndYear, watchBaseYear]);

  const handleSetDataUpload = (data: IChangeDemographicsParams) =>{
    if(data?.country?.length){
      setDataUpload(data);
    }
  }

  const onAddBreakpoint = () => {
    if (fieldsBreakpoints.length < 4) {
      appendBreakpoint({ value: undefined });  // Giá trị mặc định của breakpoint
    }
  };
  const onCloseModal = () => {
    setShowModalUpload(false);
  };
  const onDeleteBreakpoint = (index: number) => {
    if (fieldsBreakpoints?.length <= 1) return;
    removeBreakpoint(index);
  };

  const onReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    window.location.href = appRoutes.private.home;
  };

  const onClickButtonUpload = () => {
    if(isHaveDatatabe){
      setConfirmModal({
        titleBtnYes: "Yes",
        isOpen: true,
        title: 'Upload file scenario',
        description:
          "You have selected to load a saved scenario. Do you want to overwrite only the revised data set or both the revised and the basic data sets?",
        onSubmit: () => {setShowModalUpload(true); setConfirmModal(null);}
      });
      return;
    }
    setShowModalUpload(true);
    setConfirmModal(null);
    return
    // if (changedVariables?.length > 0) {
    //   setConfirmModal({
    //     isOpen: true,
    //     title: 'Get new demographics data',
    //     description:
    //       "Are you sure you want to reset everything to base? This action will restore all the changed variables, if you want to keep them and apply new data control, please click on the 'Run new scenario' button.",
    //     onSubmit: () => {setShowModalUpload(true); setConfirmModal(null);}
    //   });
    //   // setShowModalUpload(true); 
    //   // setConfirmModal(null);
    // }else{
    //   setShowModalUpload(true)
    // }
  }

  const onCloseModalConfirm = () => {
    setConfirmModal(null);
  };

  const _onSubmit = (data: IGetDemographicsFormData)=>{
    onSubmit(data)
  }

  return (
    <div className={classes.optionsWrapper}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<CaretDownIcon className={classes.chevronIcon} />}>
          <strong>Data control:</strong>
          <SecondaryButton onClick={onReset}>Reset</SecondaryButton>
        </AccordionSummary>
        
        <AccordionDetails>
          <form onSubmit={handleSubmit(_onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
              <Box sx={{display: "flex", gap:"12px"}}>
                  <Grid className={classes.submitButtonWrapper}>
                    <Grid className={classes.btnUploadData} onClick={onClickButtonUpload}>
                      Upload a saved scenario
                    </Grid>
                  </Grid>
                  <SecondaryButton type='button' onClick={onSettingReport}>Report</SecondaryButton>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Select
                  collapsible
                  label="Country"
                  placeholder="Select countries"
                  isMulti
                  options={
                    dataControl?.regions?.map((region) => ({
                      id: region.id,
                      label: region.name,
                      options: orderBy(region.countries, ['name'], ['asc'])?.map((country) => ({
                        value: country.id,
                        label: country.name,
                        disabled: !(+country?.status),
                      })),
                    })) ?? []
                  }
                  name="country"
                  control={control}
                  isOptionDisabled={(option: any) => option.disabled}
                  errorMessage={errors?.country?.message as string}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <Select
                  label="Topic"
                  placeholder="Select topic set to display"
                  isMulti
                  options={[
                    {
                      label: '',
                      options: dataControl?.topics?.map((topic) => ({ value: topic.id, label: topic.name })) ?? [],
                    },
                  ]}
                  name="topic"
                  control={control}
                  errorMessage={errors?.topic?.message as string}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <Select
                  label="Sub table: To change variables, select 'Revised'"
                  placeholder="Select sub tables to show"
                  isMulti
                  options={[
                    {
                      label: '',
                      options: dataControl?.subTables?.map((subTable) => ({ value: subTable.id, label: subTable.name })) ?? [],
                    },
                  ]}
                  name="subTable"
                  control={control}
                  errorMessage={errors?.subTable?.message as string}
                />
              </Grid>
              <Grid className={clsx(classes.submitButtonWrapper,classes.desktop)} item xs={12} sm={12} md={3}>
                <PrimaryButton fullWidth type="submit">
                  {isContryChange ? "Load new data set" :  isDataControlChange ? "Update Data Display" :"Apply changes"}
                </PrimaryButton>
              </Grid>
              {showCustomBreakpoints ? (
                <Grid item xs={12}>
                  <div className={classes.breakpointWrapper}>
                    <Stack alignItems="center" direction="row">
                      <label>Household Income Breaks US$ pa:</label>
                      <Controller
                        name="customBreakpoints"
                        control={control}
                        render={({ field: { onChange, value } }) => <Switch className={classes.switch} checked={value ?? false} onChange={onChange} />}
                      />
                    </Stack>

                    {watch('customBreakpoints') === true ? (
                      <div className={classes.breakpointList}>
                        <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }} alignItems="start" flexWrap="wrap" rowGap={2} columnGap={3}>
                          {fieldsBreakpoints.map((field, index) => (
                            <div className={classes.breakpointItem} key={field.id}>
                              <span>
                                <strong>
                                  {index <= 0
                                    ? `$0`
                                    : watch('breakpoints')?.[index - 1]?.value && !errors?.breakpoints?.[index - 1]?.value
                                    ? `$${CommonService.formatNumber(watch('breakpoints')?.[index - 1]?.value, 2)}`
                                    : 'N/A'}
                                </strong>{' '}
                                to{' '}
                              </span>

                              <Input
                                className={classes.breakpointInput}
                                type="number"
                                step="any"
                                inputRef={register(`breakpoints.${index}.value`)}
                                errorMessage={errors.breakpoints?.[index]?.value?.message}
                              />

                              {index > 0 ? (
                                <div className={classes.breakpointButton}>
                                  <button onClick={() => onDeleteBreakpoint(index)} type="button" >
                                    <CloseIcon />
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          ))}

                          <div className={classes.breakpointItem}>
                            <span>
                              Over{' '}
                              <strong>
                                {watch('breakpoints')?.[fieldsBreakpoints?.length - 1]?.value &&
                                !errors?.breakpoints?.[fieldsBreakpoints?.length - 1]?.value
                                  ? `$${CommonService.formatNumber(watch('breakpoints')?.[fieldsBreakpoints?.length - 1]?.value, 2)}`
                                  : 'N/A'}
                              </strong>
                            </span>

                            <div className={classes.breakpointButton}>
                              <button onClick={onAddBreakpoint} type="button" className={clsx({ [classes.disableButton]: !(fieldsBreakpoints.length < 4) })}>
                                <PlusBlackIcon />
                              </button>
                            </div>
                          </div>
                        </Stack>
                      </div>
                    ) : null}
                  </div>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CustomDatePicker
                      label="Start year"
                      views={['year']}
                      control={control}
                      name="startYear"
                      errorMessage={errors?.startYear?.message as string}
                      datePickerProps={{
                        minDate: dayjs(`${dataControl?.startYear ?? 2005}`),
                        maxDate: dayjs(`${dataControl?.endYear ?? 2065}`),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <CustomDatePicker
                      label="End year"
                      views={['year']}
                      control={control}
                      name="endYear"
                      errorMessage={errors?.endYear?.message as string}
                      datePickerProps={{
                        minDate: dayjs(`${dataControl?.startYear ?? 2005}`),
                        maxDate: dayjs(`${dataControl?.endYear ?? 2065}`),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Input
                      className={classes.numberInput}
                      label="Step size"
                      type="number"
                      placeholder="Select step year size"
                      min={1}
                      inputRef={register('stepSize')}
                      errorMessage={errors?.stepSize?.message}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <CustomDatePicker
                      label="Base year"
                      views={['year']}
                      control={control}
                      name="baseYear"
                      errorMessage={errors?.baseYear?.message as string}
                      datePickerProps={{
                        minDate: dayjs(`${dataControl?.startYear ?? 2005}`),
                        maxDate: dayjs(`${dataControl?.endYear ?? 2065}`),
                      }}
                    />
                  </Grid>

                  <Grid className={clsx(classes.submitButtonWrapper,classes.mobile)} item xs={12} sm={12} md={12}>
                    <PrimaryButton fullWidth type="submit">
                      {isContryChange ? "Load new data set" :  isDataControlChange ? "Update Data Display" :"Apply changes"}
                    </PrimaryButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <ModalUploadScenario
        isOpen={showModalUpload}
        onClose={onCloseModal}
        handleSetDataUpload={handleSetDataUpload}
      />
      <ConfirmModal
        isOpen={confirmModal?.isOpen}
        title={confirmModal?.title}
        description={confirmModal?.description}
        onSubmit={confirmModal?.onSubmit}
        titleBtnYes={confirmModal?.titleBtnYes}
        onClose={onCloseModalConfirm}
      />
    </div>
  );
});

export default DataControl;
