import { memo } from 'react';
import Layout from '../Layout';
import Heading6 from '../Heading6';
import { Box } from '@mui/material';
import { TableHeaderLabel } from '../Table/TableHead';
import Chart from '../ChartArea';
import TablePreview, { DataTables, ETYPE_ROW } from '../Table';

// eslint-disable-next-line no-empty-pattern
//interface Props {}

const tableHeaders: TableHeaderLabel[] =[
  {
    name: "",
    label: "",
    align: "left"
  },
  {
    name: "",
    label: "",
    align: "center"
  },
  {
    name: "2010",
    label: "2010",
    align: "center"
  },
  {
    name: "2015",
    label: "2015",
    align: "center"
  },
  {
    name: "2020",
    label: "2020",
    align: "center"
  },
  {
    name: "2025",
    label: "2025",
    align: "center"
  },
  {
    name: "2030",
    label: "2030",
    align: "center"
  },
  {
    name: "2035",
    label: "2035",
    align: "center"
  },
  {
    name: "2040",
    label: "2040",
    align: "center"
  },
  {
    name: "2045",
    label: "2045",
    align: "center"
  },
]
const dataTables: DataTables[] = [
  {
    type: ETYPE_ROW.SUBTABLE,
    subTitle:"Base Case",
  },
  {
    type: ETYPE_ROW.DEFAULT,
    data:[
      "Total Population",
      "000s",
      311.183,
      324.608,
      335.942,
      338.103,
      343.836,
      351.405,
      357.183,
      361.528,
    ]
  },
  {
    type: ETYPE_ROW.DEFAULT,
    data:[
      "Total Households",
      "000s",
      311.183,
      324.608,
      335.942,
      338.103,
      343.836,
      351.405,
      357.183,
      361.528,
    ]
  },
  {
    type: ETYPE_ROW.DEFAULT,
    data:[
      "Avg Household Size",
      "000s",
      311.183,
      324.608,
      335.942,
      338.103,
      343.836,
      351.405,
      357.183,
      361.528,
    ]
  },
  {
    type: ETYPE_ROW.SUBTABLE,
    subTitle:"Scenario"
  },
  {
    type: ETYPE_ROW.DEFAULT,
    data:[
      "Total Population",
      "000s",
      311.183,
      324.608,
      335.942,
      338.103,
      343.836,
      351.405,
      357.183,
      361.528,
    ]
  },
  {
    type: ETYPE_ROW.DEFAULT,
    data:[
      "Total Households",
      "000s",
      311.183,
      324.608,
      335.942,
      338.103,
      343.836,
      351.405,
      357.183,
      361.528,
    ]
  },
  {
    type: ETYPE_ROW.DEFAULT,
    data:[
      "Avg Household Size",
      "000s",
      311.183,
      324.608,
      335.942,
      338.103,
      343.836,
      351.405,
      357.183,
      361.528,
    ]
  },
]
const Page3 = memo(() => {
  return (
    <Layout>
      <Heading6 $fontWeight={600} $colorName="--red">
        Total Population, Households and Household Size
      </Heading6>
      <Box pt={2}>
        <TablePreview
          tableHeaders={tableHeaders}
          dataTables={dataTables}
        />
      </Box>
      <Box width={"100%"} pt={2} display={'flex'} gap={1}>
        <Box width={"calc(50% - 8px)"}>
          <Chart title='Total Population (000s)' isPercentage={false} />
        </Box>
        <Box  width={"calc(50% - 8px)"}>
          <Chart title='Total Population (000s)' isPercentage={false} />
        </Box>
      </Box>
      
    </Layout>
  );
});
export default Page3
