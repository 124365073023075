import classes from './styles.module.scss';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
} from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import { DataFilter } from '..';
import InputSelect from './component/InputsSelect';
import TextTitle from './component/TextTitle';
import { ReportConfig } from 'interfaces/report';

interface ModalSettingReportProps {
  isOpen: boolean;
  onClose: () => void;
  dataFilter?: DataFilter;
  hiddenSubTableIds?: number[];
  addReportConfig: (data: ReportConfig) => void;
  dataConfigReport: ReportConfig[];
  onGenerateReport: (callback: () =>void) => void;
}
export interface OptionItem<T = number> {
  id: T;
  name: string;
  title?: string;
  fullName?: string;
  translation?: any;
  img?: string;
  subName?: string;
  icon?: any;
  symbol?: string;
  result?: boolean;
}
interface ConfigFromData {
  years: OptionItem[];
  topicId: OptionItem;
  variableIds: OptionItem[];
  dataShow: string[];
}
const schema = Yup.object().shape({
  years: Yup.array(
    Yup.object().shape({
      id: Yup.number().required('Years is required.'),
      name: Yup.string().required(),
    })
  )
    .max(5, 'You can select up to 5 years.')
    .min(1, 'At least one year is required.')
    .required('Years field is required.'),
  topicId: Yup.object()
    .shape({
      id: Yup.number().required('Topic is required.'),
      name: Yup.string().required(),
    })
    .required('Topic field is required'),
  variableIds: Yup.array(
    Yup.object().shape({
      id: Yup.number().required('Variable is required.'),
      name: Yup.string().required(),
    })
  )
    .max(5, 'You can select up to 5 variables.')
    .min(1, 'At least one year is required.')
    .required('Variable is required.'),
  dataShow: Yup.array().nullable().min(1, 'Data which to display is required.'),
});

const ModalSettingReport = memo(({ isOpen, onClose, dataFilter, onGenerateReport, addReportConfig, dataConfigReport }: ModalSettingReportProps) => {
  const [page, setPage] = useState<number>(1);
  const { dataControl } = useSelector((state: IReducer) => state.dataControl);
  const [optionVariable, setOptionVariable] = useState<OptionItem[]>([]);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
  } = useForm<ConfigFromData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      dataShow: [],
    },
  });

  const _addReportConfig = async (data: ReportConfig) =>{
    await addReportConfig(data)
    const _page: number = page + 1
    setPage(_page)
    _resetForm();
  }

  const _resetForm = () =>{
    reset({
      years: [],
      topicId: null,
      variableIds: [],
      dataShow: [],
    });
  }

  const onSubmit = (data: ConfigFromData) => {
    const _data: ReportConfig = {
      ...data,
      page: page
    }
    _addReportConfig(_data)
  };

  const optionsYear: OptionItem[] = useMemo(() => {
    if (dataFilter?.yearRange?.length) {
      return dataFilter?.yearRange?.map((y) => ({ id: y, name: y.toString() })) ?? [];
    } else {
      return [];
    }
  }, [dataFilter?.yearRange]);

  const prePage = () => {
    const _page: number = page - 1
    setPage(_page)
    
  }

  const _onClose = () => {
    setPage(1)
    _resetForm()
    onClose()
  }
  const _setOptionVariable = async (id: number) => {
    const _optionVariable: OptionItem[] = await dataControl?.topics
    ?.find((t) => t.id === id)
    ?.fields?.map((it) => ({ id: it.variableId, name: it.name }));
    setOptionVariable(_optionVariable ?? []);
  }

  useEffect(() => {
    const fetchData = async (_page: number) =>{
      const getData = await dataConfigReport.find(it=> it.page === _page);
      if(getData){
        await _setOptionVariable(getData.topicId.id)
        reset({
          years: getData.years,
          variableIds: getData.variableIds,
          topicId: getData.topicId,
          dataShow: getData.dataShow
        })
      }
    }
    fetchData(page);
  },[page])

  const selectedValues = watch('dataShow');

  const onChangeSelectTopic = (e: any) =>{
    const selected :OptionItem = e as OptionItem;
    setValue('variableIds', []);
    if(selected?.id){
      _setOptionVariable(selected.id)
      setValue("topicId", selected)
    }
  }

  const _onGenerateReport = () =>{
    onGenerateReport(_onClose)
  }

  useEffect(() => {
    console.log(dataConfigReport, '9999999999999');
  }, [dataConfigReport]);
  return (
    <Dialog
      PaperProps={{ style: { overflowY: 'visible' } }}
      maxWidth={'md'}
      transitionDuration={0.3}
      open={isOpen}
      onClose={_onClose}
      className={classes.container}
      sx={{ overflow: 'hidden' }}
    >
      <DialogTitle className={classes.title} textAlign={'center'}>
        Configs report
      </DialogTitle>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ overflowY: { md: 'visible' } }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ borderRadius: '10px' }}>
                <CardContent className={classes.content} sx={{ padding: '20px 0' }}>
                  <Box width={'100%'} textAlign={'center'}>
                    <strong>Config page {page}</strong>
                  </Box>
                  <Box width={'100%'}>
                    <Grid container columnSpacing={6}>
                      <Grid item xs={12} sm={12}>
                        <InputSelect
                          title="Topic"
                          name="topicId"
                          control={control}
                          fullWidth
                          
                          selectProps={{
                            options: (dataControl?.topics ?? [])
                            .filter((topic) => dataFilter?.topic?.includes(topic.id))
                            .map((topic) => ({
                              id: topic.id,
                              name: topic.name,
                            })),
                            onChange: (e) => {
                              onChangeSelectTopic(e)
                            },
                          }}
                          errorMessage={(errors.topicId as any)?.id?.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <InputSelect
                          title="Variables"
                          name="variableIds"
                          control={control}
                          fullWidth
                          selectProps={{
                            isMulti: true,
                            options: optionVariable ?? [],
                          }}
                          errorMessage={(errors.variableIds as any)?.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <InputSelect
                          title="Years"
                          name="years"
                          control={control}
                          fullWidth
                          selectProps={{
                            isMulti: true,
                            options: optionsYear ?? [],
                          }}
                          errorMessage={(errors.years as any)?.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextTitle>Data which to display</TextTitle>
                        <Controller
                          name="dataShow"
                          control={control}
                          render={({ field }) => (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    value="BASE"
                                    disabled={!dataFilter?.subTable?.includes(1)}
                                    checked={selectedValues.includes('BASE')}
                                    onChange={(e) => {
                                      const newValue = e.target.checked ? [...field.value, 'BASE'] : field.value.filter((val) => val !== 'BASE');
                                      field.onChange(newValue);
                                    }}
                                  />
                                }
                                label="Base case"
                              />

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    value="SCENARIO"
                                    disabled={!dataFilter?.subTable?.includes(2)}
                                    checked={selectedValues.includes('SCENARIO')}
                                    onChange={(e) => {
                                      const newValue = e.target.checked ? [...field.value, 'SCENARIO'] : field.value.filter((val) => val !== 'SCENARIO');
                                      field.onChange(newValue);
                                    }}
                                  />
                                }
                                label="Scenario"
                              />
                            </>
                          )}
                        />
                        {(errors.dataShow as any)?.message && <p className={classes.textError}>{errors.dataShow.message}</p>}
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ mt: 10 }} className={classes.dialogAction}>
          <Box display={"flex"} gap={2} justifyContent={"space-between"} width={"100%"} sx={{ ml: 'auto' }}>
            <SecondaryButton disabled={!!(page <=1)} type='button' onClick={prePage}>Previous page</SecondaryButton>
            <PrimaryButton onClick={_onGenerateReport}>Finished - Generate Report</PrimaryButton>
            <SecondaryButton disabled={!!(page >= 9)}type="submit">Next page</SecondaryButton>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
});
export default ModalSettingReport;
