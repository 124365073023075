import { Typography } from '@mui/material';
import styled from 'styled-components';

interface TextTitleProps {
  invalid?: string
}

const TextTitle = styled(Typography)<TextTitleProps>`
  font-size: 14px;
  font-weight: 500;
  font-weight: 600;
  margin: 0 2px 2px;
`
export default TextTitle