import { TableHead, TableRow, TableCell } from '@mui/material';
import classes from './styles.module.scss';
import clsx from 'clsx';

export interface TableHeaderLabel {
  name: string;
  label: string;
  align?: 'center'
  | 'inherit'
  | 'justify'
  | 'left'
  | 'right'
}
interface Props {
  className?: string
  headers: TableHeaderLabel[];
}

const TableHeader = (props: Props) => {
  const { headers, className } = props;

  return (
    <TableHead className={clsx(className, classes.tableHead)}>
      <TableRow>
        {
          headers.map(header => (
            <TableCell
              align={header.align || "center"}
              key={header.name}
              className={classes.tableCell}
            >
              {header.label}
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  )
}

export default TableHeader